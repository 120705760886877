import { Command } from 'ckeditor5/src/core'

export default class QrResizeCommand extends Command {
  refresh() {
    const editor = this.editor
    const element = editor.model.document.selection

    this.isEnabled = true

    if (!element || !element.hasAttribute('width')) {
      this.value = null
    } else {
      this.value = {
        width: element.getAttribute('width'),
        height: null,
      }
    }
  }

  execute(options) {
    const editor = this.editor
    const model = editor.model
    const imageElement = getClosestSelectedImageElement(
      model.document.selection
    )

    this.value = {
      width: options.width,
      height: null,
    }

    if (imageElement) {
      model.change(writer => {
        writer.setAttribute('width', options.width, imageElement)
      })
    }
  }
}

function getClosestSelectedImageElement(selection) {
  const selectedElement = selection.getSelectedElement()

  return (
    selectedElement ??
    selection.getFirstPosition().findAncestor('template-field') ??
    selection.getFirstPosition().findAncestor('type-field') ??
    selection.getFirstPosition().findAncestor('master-field')
  )
}

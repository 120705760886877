export const personFields = [
  {
    key: 'passportType',
    label: 'passportType',
    innerType: 'select',
    innerOptions: [
      { value: 'сitizen', label: 'сitizen' },
      { value: 'notСitizen', label: 'notСitizen' },
    ],
    defaultValue: 'сitizen',
  },
  {
    key: 'nationality',
    label: 'nationality',
    innerType: 'select',
    innerOptions: [
      { value: 'RUS', label: 'RUS' },
      { value: 'BLR', label: 'BLR' },
      { value: 'UZB', label: 'UZB' },
      { value: 'KAZ', label: 'KAZ' },
      { value: 'KGZ', label: 'KGZ' },
      { value: 'TJK', label: 'TJK' },
    ],
    defaultValue: 'RUS',
  },
  {
    key: 'lastName',
    label: 'lastName',
    innerType: 'input',
    defaultValue: 'Иванов',
    mask: 'AaX+',
  },
  {
    key: 'firstName',
    label: 'firstName',
    innerType: 'input',
    defaultValue: 'Виктор',
    mask: 'AaX+',
  },
  {
    key: 'middleName',
    label: 'middleName',
    innerType: 'input',
    defaultValue: 'Иванович',
    mask: 'AaX+',
  },
  {
    key: 'shortFullName',
    label: 'shortFullName',
    innerType: 'input',
    defaultValue: 'Иванов И. И.',
    mask: 'AaX+',
  },
  {
    key: 'gender',
    label: 'gender',
    innerType: 'select',
    innerOptions: [
      { value: 'man', label: 'man' },
      { value: 'woman', label: 'woman' },
    ],
    defaultValue: 'man',
  },
  {
    key: 'snils',
    label: 'snils',
    innerType: 'input',
    defaultValue: '336-587-453-33',
    mask: '###-###-### ##',
  },
  {
    key: 'passportSeries',
    label: 'passportSeries',
    innerType: 'input',
    defaultValue: '3658',
    mask: '## ##',
  },
  {
    key: 'passportNumber',
    label: 'passportNumber',
    innerType: 'input',
    defaultValue: '55898',
    mask: '######',
  },
  {
    key: 'passportWhoReleased',
    label: 'passportWhoReleased',
    innerType: 'input',
    defaultValue: 'ОУФМ России по Московской обл',
  },
  {
    key: 'passportWhenReleased',
    label: 'passportWhenReleased',
    innerType: 'date',
    defaultValue: new Date(),
    format: 'DD.MM.YYYY',
  },
  {
    key: 'passportDivisionCode',
    label: 'passportDivisionCode',
    innerType: 'input',
    defaultValue: '568-985',
    mask: '###-###',
  },
  {
    key: 'registrationDate',
    label: 'registrationDate',
    innerType: 'date',
    defaultValue: new Date(),
    format: 'DD.MM.YYYY',
  },
  {
    key: 'registrationAddress',
    label: 'registrationAddress',
    innerType: 'input',
    defaultValue: 'г. Москва ул. Ленина д. 1 кв. 45',
  },
  {
    key: 'subject',
    label: 'subject',
    innerType: 'input',
    defaultValue: 'Московская область',
  },
  {
    key: 'city',
    label: 'city',
    innerType: 'input',
    defaultValue: 'Воскресенск',
  },
  {
    key: 'district',
    label: 'district',
    innerType: 'input',
    defaultValue: 'Воскресенский район',
  },
  {
    key: 'populatedArea',
    label: 'populatedArea',
    innerType: 'input',
    defaultValue: 'Воскресенск',
  },
  {
    key: 'street',
    label: 'street',
    innerType: 'input',
    defaultValue: 'Новлянская',
  },
  {
    key: 'house',
    label: 'house',
    innerType: 'input',
    defaultValue: '6',
  },
  {
    key: 'buildingShell',
    label: 'buildingShell',
    innerType: 'input',
    defaultValue: '6',
  },
  {
    key: 'apartment',
    label: 'apartment',
    innerType: 'input',
    defaultValue: '122',
  },
  {
    key: 'birthdate',
    label: 'birthdate',
    innerType: 'date',
    defaultValue: new Date(),
    format: 'DD.MM.YYYY',
  },
  {
    key: 'birthplace',
    label: 'birthplace',
    innerType: 'input',
    defaultValue: 'г. Москва',
  },
  {
    key: 'contactName',
    label: 'contactName',
    innerType: 'input',
    defaultValue: 'Иванов Иван Иванович',
    mask: 'AaX+',
  },
  {
    key: 'phone',
    label: 'phone',
    innerType: 'input',
    defaultValue: '+7 (925) 062-22-15',
    mask: '!+7 (###) ###-##-##',
  },
  {
    key: 'email',
    label: 'email',
    innerType: 'email',
    defaultValue: 'test@test.ru',
  },
]

export const legalFields = [
  {
    key: 'formOfOrganization',
    label: 'formOfOrganization',
    innerType: 'select',
    innerOptions: [
      { value: 'ip', label: 'ip' },
      { value: 'ooo', label: 'ooo' },
    ],
    defaultValue: 'ip',
  },
  {
    key: 'completeName',
    label: 'completeName',
    innerType: 'input',
    defaultValue: 'ИП Иванов А.К.',
  },
  {
    key: 'legalRegistrationDate',
    label: 'legalRegistrationDate',
    innerType: 'date',
    defaultValue: new Date(),
    format: 'DD.MM.YYYY',
  },
  {
    key: 'shortName',
    label: 'shortName',
    innerType: 'input',
    defaultValue: 'ИП Иванов',
  },
  {
    key: 'ogrn',
    label: 'ogrn',
    innerType: 'input',
    defaultValue: '1111111111111',
    mask: '#############',
  },
  {
    key: 'inn',
    label: 'inn',
    innerType: 'input',
    defaultValue: '1111111111',
    mask: '##########',
  },
  {
    key: 'bank',
    label: 'bank',
    innerType: 'input',
    defaultValue: 'Тинькофф',
  },
  {
    key: 'bik',
    label: 'bik',
    innerType: 'input',
    defaultValue: '111111111',
    mask: '#########',
  },
  {
    key: 'kpp',
    label: 'kpp',
    innerType: 'input',
    defaultValue: '111111111',
    mask: '#########',
  },
  {
    key: 'billingAccount',
    label: 'billingAccount',
    innerType: 'input',
    defaultValue: '11111111111111111111',
    mask: '####################',
  },
  {
    key: 'correspondentAccount',
    label: 'correspondentAccount',
    innerType: 'input',
    defaultValue: '11111111111111111111',
    mask: '####################',
  },
  {
    key: 'treasuryAccount',
    label: 'treasuryAccount',
    innerType: 'input',
    defaultValue: '',
  },
  {
    key: 'singleTreasuryAccount',
    label: 'singleTreasuryAccount',
    innerType: 'input',
    defaultValue: '',
  },
  {
    key: 'legalAddress',
    label: 'legalAddress',
    innerType: 'input',
    defaultValue: 'г. Москва ул. Ленина д. 1',
  },
  {
    key: 'postalAddress',
    label: 'postalAddress',
    innerType: 'input',
    defaultValue: 'г. Москва ул. Ленина д. 1',
  },
  {
    key: 'contactName',
    label: 'contactName',
    innerType: 'input',
    defaultValue: 'Иванов Иван Иванович',
    mask: 'AaX+',
  },
  {
    key: 'phone',
    label: 'phone',
    innerType: 'input',
    defaultValue: '+7 (999) 888-77-66',
    mask: '!+7 (###) ###-##-##',
  },
  {
    key: 'email',
    label: 'email',
    innerType: 'input',
    defaultValue: 'test@test.ru',
  },
  {
    key: 'signer',
    label: 'signer',
    innerType: 'input',
    defaultValue: 'Директор',
  },
  {
    key: 'signerName',
    label: 'signerName',
    innerType: 'input',
    defaultValue: 'Иванов Иван Иванович',
    mask: 'AaX+',
  },
  {
    key: 'signerRCase',
    label: 'signerRCase',
    innerType: 'input',
    defaultValue: 'Директора',
  },
  {
    key: 'signerNameRCase',
    label: 'signerNameRCase',
    innerType: 'input',
    defaultValue: 'Иванова Ивана Ивановича',
    mask: 'AaX+',
  },
  {
    key: 'actBasis',
    label: 'actBasis',
    innerType: 'input',
    defaultValue: 'Доверенность',
  },
]

export const dateFormats = [
  { value: 'YYYY г.', label: '2022 г.' },
  { value: 'DD/MM/YY', label: '31/01/22' },
  { value: 'DD/MM/YY г.', label: '31/01/22 г.' },
  { value: 'DD.MM.YY', label: '31.01.22' },
  { value: 'DD.MM.YY г.', label: '31.01.22 г.' },
  { value: 'DD.MM.YYYY', label: '31.01.2022' },
  { value: 'DD.MM.YYYY г.', label: '31.01.2022 г.' },
  { value: 'DD/MM/YYYY', label: '31/01/2022' },
  { value: 'DD/MM/YYYY г.', label: '31/01/2022 г.' },
  { value: 'DD MMMM', label: '31 декабря' },
  { value: 'DD MMMM YYYY г.', label: '31 декабря 2022 г.' },
]

export const customersTypeOptions = [
  { value: 'person', label: 'person' },
  { value: 'legal', label: 'legal' },
]

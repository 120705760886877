import Plugin from '@ckeditor/ckeditor5-core/src/plugin'
import { ButtonView } from '@ckeditor/ckeditor5-ui/'

import { getRandomId } from '@/utils/helpers'

export default class FieldUI extends Plugin {
  init() {
    const editor = this.editor

    editor.ui.componentFactory.add('master-field', locale => {
      const buttonView = new ButtonView(locale)

      buttonView.set({
        tooltip: 'Поле главного документа',
        withText: false,
        class: 'border-warning text-warning font-small-2 ck-field-button',
        icon: typeIcon,
      })

      const command = editor.commands.get('master-field')
      buttonView.bind('isEnabled').to(command)

      this.listenTo(buttonView, 'execute', evt => {
        editor.execute('master-field', {
          type: 'masterField',
          id: getRandomId(),
        })
        editor.editing.view.focus()
      })

      return buttonView
    })
  }
}

const typeIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-type"><polyline points="4 7 4 4 20 4 20 7"></polyline><line x1="9" y1="20" x2="15" y2="20"></line><line x1="12" y1="4" x2="12" y2="20"></line></svg>'

<template>
  <span
    :contenteditable="false"
    :id="`select-field-${field.id}`"
    :class="isValidField ? '' : 'select-field-invalid'"
  >
    <span>{{ model || $t('editor.selectValue') }}</span>

    <b-popover
      v-if="!field.disabled"
      :target="`select-field-${field.id}`"
      :title="$t('forms.labels.selectOption')"
      triggers="click"
      placement="top"
      custom-class="field-popover"
      @shown="e => e.relatedTarget.focus()"
      @click.stop="e => e.preventDefault()"
    >
      <FormInputGroup
        v-if="editorType === 'editor'"
        v-model="optionModel"
        :label="$t('forms.labels.addOption')"
      >
        <template #append>
          <b-button
            v-ripple
            variant="primary"
            :disabled="!optionModel"
            @click="addOption"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>
        </template>
      </FormInputGroup>

      <FormSelectGroup
        v-if="editorType === 'viewer'"
        :value.sync="model"
        :options="options"
        :label="$t('forms.labels.selectOption')"
        @change="inputHandler"
      />

      <b-list-group v-if="editorType === 'editor'">
        <b-list-group-item
          v-for="(option, _i) in options"
          :key="option.label"
          :active="option.value === model"
          class="d-flex justify-content-between align-items-center cursor-pointer"
          @click="() => inputHandler(option.value)"
        >
          <span class="font-small-2">{{ option.label }}</span>
          <b-button
            :disabled="option.value === model"
            v-ripple
            variant="danger"
            size="sm"
            class="field__delete btn-icon p-0"
            @click="options.splice(_i, 1)"
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </b-list-group-item>
      </b-list-group>
    </b-popover>
  </span>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {
  ref,
  computed,
  getCurrentInstance,
  onMounted,
} from '@vue/composition-api'
import {
  BFormInput,
  BPopover,
  BButton,
  BRow,
  BCol,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import { required } from '@vuelidate/validators'
import { FormInputGroup, FormSelectGroup } from '@/views/components/forms'

export default {
  components: {
    BFormInput,
    BPopover,
    BButton,
    BRow,
    BCol,
    FormSelectGroup,
    FormInputGroup,
    BListGroup,
    BListGroupItem,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    field: {
      type: Object,
      default: () => ({
        defaultValue: '',
        id: '',
        value: null,
        disabled: false,
      }),
    },
    editorType: {
      type: String,
      default: 'viewer',
    },
  },
  // mounted() {
  //   this.$root.$on('bv::popover::show', bvEventObj => {
  //     const el = document.querySelector('#app')
  //     el.addEventListener('click', () => {
  //       this.$root.$emit('bv::hide::popover')
  //     })
  //   })
  // },
  setup(props, { emit }) {
    const model = ref(props.value)
    const optionModel = ref('')
    const options = ref(props.field.options || [])

    const addOption = () => {
      v$.value.$touch()
      if (!isValid.value) return
      options.value.push({ value: optionModel.value, label: optionModel.value })
      emit('update:field', { ...props.field, options: options.value })
    }

    const inputHandler = value => {
      model.value = value
      if (props.editorType === 'viewer') {
        emit('update:field', { ...props.field, options: options.value, value })
      } else {
        emit('update:field', {
          ...props.field,
          options: options.value,
          defaultValue: value,
        })
      }
    }

    const rules = {
      optionModel: {
        required,
        duplicate: value => !options.value.some(opt => opt.value === value),
        $autoDirty: true,
      },
    }
    const v$ = useVuelidate(rules, { optionModel })
    const isValid = computed(() => !v$.value.$error)
    const isValidField = computed(() => !!model.value)

    onMounted(() => {
      const root = getCurrentInstance()?.proxy?.$root
      const el = document.querySelector('#app')
      function hidePopover() {
        root?.$emit('bv::hide::popover')
      }
      if (root) {
        root.$on('bv::popover::show', () => {
          el.addEventListener('click', hidePopover)
        })
        root.$on('bv::popover::hide', () => {
          el.removeEventListener('click', hidePopover)
        })
      }
    })

    return {
      model,
      isValidField,
      options,
      optionModel,
      inputHandler,
      addOption,
    }
  },
}
</script>

<style lang="scss">
.editor {
  &__select {
    display: inline;
    outline: 0;
    transition: all 0.3s;
    cursor: pointer !important;
    background: rgba(115, 103, 240, 0.3);

    .select-field-invalid {
      border-bottom: 1px solid rgba(220, 53, 69, 0.5);
    }
  }
}

@media print {
  .editor {
    &__select {
      background: transparent;
      border: 0;
      padding: 0;
      outline: 0;
    }
  }
}
</style>

<template>
  <ckeditor
    v-model="editorData"
    :editor="editor"
    :disabled="!editable"
    :config="editorConfig"
    ref="editor"
    @ready="onReady"
  />
</template>

<script>
import Vue from 'vue'
import Inserter from '@/views/components/editor/Inserter'
import i18n from '@/libs/i18n'
import { BModal } from 'bootstrap-vue'
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { FormInputGroup, FormSelectGroup } from '@/views/components/forms'

import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline'
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough'
import Table from '@ckeditor/ckeditor5-table/src/table'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties'
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties'
import TableColumnResize from '@ckeditor/ckeditor5-table/src/tablecolumnresize'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment'
import FontFamily from '@ckeditor/ckeditor5-font/src/fontfamily'
import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor'
import FontSize from '@ckeditor/ckeditor5-font/src/fontsize'
import FontBackgroundColor from '@ckeditor/ckeditor5-font/src/fontbackgroundcolor'
import Heading from '@ckeditor/ckeditor5-heading/src/heading'
import PageBreak from '@ckeditor/ckeditor5-page-break/src/pagebreak'
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice'
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat'
import SpecialCharacters from '@ckeditor/ckeditor5-special-characters/src/specialcharacters'
import SpecialCharactersEssentials from '@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials'
import Indent from '@ckeditor/ckeditor5-indent/src/indent'
import IndentBlock from '@ckeditor/ckeditor5-indent/src/indentblock'
import List from '@ckeditor/ckeditor5-list/src/list'
import LineHeight from './line-height'
import QrResizer from './qr-resizer'
import TemplateField from './template-field/'
import TypeField from './type-field/'
import CustomerField from './customer-field/'
import MasterField from './master-field/'
import AdditionalCustomersField from './additional-customers-field/'

export default {
  emits: [
    'update:content-data',
    'update:fields',
    'update:field',
    'add:field',
    'update:template-field',
    'update:type-field',
    'delete:field',
  ],
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BCard,
    FormInputGroup,
    FormSelectGroup,
    BDropdown,
    BDropdownItem,
  },
  props: {
    contentData: {
      type: String,
      default: '',
    },
    templateFields: {
      type: Array,
      default: () => [],
    },
    masterFields: {
      type: Array,
      default: () => [],
    },
    typeFields: {
      type: Array,
      default: () => [],
    },
    customerFields: {
      type: Array,
      default: () => [],
    },
    additionalCustomers: {
      type: Object,
      default: () => {},
    },
    type: {
      type: Object,
      default: () => {},
    },
    agreementType: {
      type: Object,
      default: () => {},
    },
    toolbar: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fieldsRecord: {},
      editor: ClassicEditor,
      editorData: this.contentData,
      editorConfigDefault: {
        plugins: [
          Underline,
          Indent,
          IndentBlock,
          Strikethrough,
          EssentialsPlugin,
          BoldPlugin,
          ItalicPlugin,
          LinkPlugin,
          ParagraphPlugin,
          Table,
          TableToolbar,
          TableProperties,
          TableCellProperties,
          TableColumnResize,
          Alignment,
          FontFamily,
          FontColor,
          FontSize,
          FontBackgroundColor,
          Heading,
          PageBreak,
          PasteFromOffice,
          RemoveFormat,
          SpecialCharacters,
          SpecialCharactersEssentials,
          List,
          LineHeight,
          // Custom
          TemplateField,
          TypeField,
          CustomerField,
          MasterField,
          QrResizer,
          AdditionalCustomersField,
        ],
        heading: {
          options: [
            {
              model: 'paragraph',
              title: 'Paragraph',
              class: 'ck-heading_paragraph',
            },
            {
              model: 'heading1',
              view: 'h1',
              title: 'Heading 1',
              class: 'ck-heading_heading1',
            },
            {
              model: 'heading2',
              view: 'h2',
              title: 'Heading 2',
              class: 'ck-heading_heading2',
            },
            {
              model: 'heading3',
              view: 'h3',
              title: 'Heading 3',
              class: 'ck-heading_heading3',
            },
            {
              model: 'heading4',
              view: 'h4',
              title: 'Heading 4',
              class: 'ck-heading_heading4',
            },
          ],
        },
        fontFamily: {
          options: [
            'default',
            'Arial, Helvetica, sans-serif',
            'Courier New, Courier, monospace',
            'Georgia, serif',
            'Lucida Sans Unicode, Lucida Grande, sans-serif',
            'Tahoma, Geneva, sans-serif',
            'Times New Roman, Times, serif',
            'Trebuchet MS, Helvetica, sans-serif',
            'Verdana, Geneva, sans-serif',
          ],
          supportAllValues: true,
        },
        fontSize: {
          options: [10, 12, 'default', 16, 18, 20, 22, 24, 26],
        },
        table: {
          contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'tableProperties',
            'tableCellProperties',
          ],
        },
        lineHeight: {
          options: [0.8, 1, 1.5, 2, 2.5],
        },
        fields: {
          types: [
            { type: 'text', label: this.$t('editor.input') },
            { type: 'date', label: this.$t('editor.date') },
            { type: 'select', label: this.$t('editor.select') },
            { type: 'list', label: this.$t('editor.list') },
            { type: 'qr', label: this.$t('editor.qr') },
          ],
          additionalCustomers: this.additionalCustomers,
          render: ({ id, type, domElement, customerKey, customerName }) => {
            const field = [
              ...this.templateFields,
              ...this.typeFields,
              ...this.customerFields,
              ...this.masterFields,
              ...Object.values(this.additionalCustomers)
                .map(({ fields }) => fields)
                .flat(),
            ].find(_f => _f.id === id || _f.ids?.includes(id)) ?? {
              type,
              id,
              customerKey,
              customerName,
            }

            field.customerKey = customerKey
            field.customerName = customerName

            field.options =
              type === 'typeField'
                ? this.typeFieldsOptions || []
                : type === 'masterField'
                ? this.masterFieldsOptions || []
                : type === 'customerField'
                ? this.customerFieldsOptions || []
                : type === 'additionalCustomersField'
                ? this.getAdditionalOptions(customerKey) || []
                : field.options || []

            const vueView = Vue.component('field-component', {
              i18n,
              render: h =>
                h(Inserter, {
                  props: {
                    field: { ...field, id, disabled: this.disabled },
                    type: this.editable ? 'editor' : 'viewer',
                  },
                }),
            })

            const instance = new vueView()
            instance.$mount()

            instance.$children[0].$on('update:field', _f => {
              this.$emit('update:field', _f)
            })

            this.fieldsRecord[id] = instance.$children[0].$children[0]
            domElement.appendChild(instance.$el)
          },
        },
      },
    }
  },
  computed: {
    editorConfig() {
      if (!this.editable) {
        return this.editorConfigDefault
      }

      const items = this.isAgreement
        ? [
            'template-field',
            'type-field',
            'customer-field',
            'additional-customers-field',
          ]
        : [
            'template-field',
            'type-field',
            'master-field',
            'customer-field',
            'additional-customers-field',
          ]

      return {
        ...this.editorConfigDefault,
        toolbar: {
          shouldNotGroupWhenFull: true,
          items: [
            ...items,
            '|',
            'heading',
            '|',
            'undo',
            'redo',
            '|',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            '|',
            'outdent',
            'indent',
            'alignment',
            'lineHeight',
            '|',
            'bulletedList',
            'numberedList',
            '|',
            'fontSize',
            'fontFamily',
            'fontColor',
            'fontBackgroundColor',
            '|',
            'removeFormat',
            '|',
            'insertTable',
            'specialCharacters',
            '|',
            'pageBreak',
          ],
        },
      }
    },
    typeFieldsOptions() {
      return (
        this.type.fields?.map(f => ({
          value: f.key,
          label: f.label,
          name: f.name,
          defaultValue: f.defaultValue,
          innerType: f.innerType,
          innerOptions: f.innerOptions,
          format: f.format,
        })) || []
      )
    },
    masterType() {
      return this.agreementType || {}
    },
    isAgreement() {
      return this.type.type === 'agreement'
    },
    masterFieldsOptions() {
      return this.masterType.fields?.map(f => ({
        value: f.key,
        label: f.label,
        name: f.name,
        defaultValue: f.defaultValue,
        innerType: f.innerType,
        innerOptions: f.innerOptions,
        format: f.format,
      }))
    },
    customerFieldsOptions() {
      return this.customerFields?.map(f => ({
        value: f.key,
        label: f.label,
        name: f.name,
        defaultValue: f.defaultValue,
        innerType: f.innerType,
        innerOptions: f.innerOptions,
        format: f.format,
      }))
    },
  },
  methods: {
    onReady(instance) {
      instance.on('insertField', (evt, field) => {
        this.$emit('add:field', field)
      })

      instance.on('updateField', (evt, field) => {
        for (const id of field.ids) {
          this.fieldsRecord[id]?.updateValue(field.value)
        }
      })
      // instance.plugins.get('FileRepository').createUploadAdapter = function (
      //   loader
      // ) {
      //   return new UploadAdapter(loader)
      // }
    },
    getAdditionalOptions(customerKey) {
      return Object.values(
        this.additionalCustomers?.[customerKey]?.fields ?? {}
      ).map(f => ({
        value: f.key,
        label: f.label,
        name: f.name,
        defaultValue: f.defaultValue,
        innerType: f.innerType,
        innerOptions: f.innerOptions,
        format: f.format,
      }))
    },
  },
  watch: {
    editorData() {
      this.$emit('update:content-data', this.editorData)
    },
  },
}

// class UploadAdapter {
//   constructor(loader) {
//     this.loader = loader
//   }
//
//   upload() {
//     return this.loader.file.then(
//       file =>
//         new Promise((resolve, reject) => {
//           var myReader = new FileReader()
//           myReader.onloadend = e => {
//             resolve({ default: myReader.result })
//           }
//
//           myReader.readAsDataURL(file)
//         })
//     )
//   }
// }
</script>

import Plugin from '@ckeditor/ckeditor5-core/src/plugin'

import FieldEditing from './editing'
import FieldUI from './ui'

export default class MasterField extends Plugin {
  static get requires() {
    return [FieldEditing, FieldUI]
  }
  static get pluginName() {
    return 'MasterField'
  }
}

import Plugin from '@ckeditor/ckeditor5-core/src/plugin'
import Model from '@ckeditor/ckeditor5-ui/src/model'
import Collection from '@ckeditor/ckeditor5-utils/src/collection'

import { createDropdown, addListToDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils'

import { isSupported, normalizeOptions } from './utils'

export default class LineHightUI extends Plugin {
    init() {
		const editor = this.editor

		const options = this._getLocalizedOptions()

		const command = editor.commands.get('lineHeight')

		// Register UI component.
		editor.ui.componentFactory.add('lineHeight', locale => {
			const dropdownView = createDropdown(locale)
			addListToDropdown(dropdownView, _prepareListOptions(options, command))

			// Create dropdown model.
			dropdownView.buttonView.set({
				label: 'Межстрочный интервал',
				icon: icon,
				tooltip: true
			})

			dropdownView.extendTemplate({
				attributes: {
					class: [
						'p0thi-ckeditor5-lineHeight-dropdown'
					]
				}
			})

			dropdownView.bind('isEnabled').to(command)

			// Execute command when an item from the dropdown is selected.
			this.listenTo(dropdownView, 'execute', evt => {
				editor.execute(evt.source.commandName, { value: evt.source.commandParam })
				editor.editing.view.focus()
			})

			return dropdownView
		})
	}

	_getLocalizedOptions() {
		const editor = this.editor
		const t = editor.t

		const localizedTitles = {
			// Default: 'Standard'
			Default: t('Default')
		}

		const options = normalizeOptions( editor.config.get('lineHeight.options').filter( option => isSupported( option ) ) )

		return options.map(option => {
			const title = localizedTitles[option.title]

			if (title && title !== option.title) {
				// Clone the option to avoid altering the original `namedPresets` from `./utils.js`.
				option = Object.assign({}, option, { title })
			}

			return option
		})
	}
}

function _prepareListOptions(options, command) {
	const itemDefinitions = new Collection()

	for (const option of options) {
		const def = {
			type: 'button',
			model: new Model({
				commandName: 'lineHeight',
				commandParam: option.model,
				label: option.title,
				class: 'p0thi-ckeditor5-lineHeight-dropdown',
				withText: true
			})
		}

		if (option.view && option.view.classes) {
			def.model.set('class', `${def.model.class} ${option.view.classes}`)
		}

		def.model.bind('isOn').to(command, 'value', value => {
			const newValue = value ? parseFloat(value) : value
			return newValue === option.model
		})

		// Add the option to the collection.
		itemDefinitions.add(def)
	}

	return itemDefinitions
}


const icon = '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"><path d="M8.211 10.578h9.474V9H8.211m0 6.317h9.474v-1.58H8.211m0-7.895h9.474V4.262H8.211m-3.158 1.58h1.974L4.263 3.077 1.5 5.84h1.974v7.896H1.5L4.263 16.5l2.764-2.763H5.053Z" style="fill:#000;stroke-width:.78953415"/></svg>'

import { Plugin } from 'ckeditor5/src/core'
import ImageUtils from '@ckeditor/ckeditor5-image/src/imageutils'
import QrResizeCommand from './command'

export default class QrResizerEditing extends Plugin {
  static get requires() {
    return [ImageUtils]
  }

  static get pluginName() {
    return 'QrResizerEditing'
  }

  constructor(editor) {
    super(editor)

    editor.config.define('image', {
      resizeUnit: 'px',
      resizeOptions: [
        {
          name: 'resizeImage:original',
          value: null,
          icon: 'original',
        },
        {
          name: 'resizeImage:25',
          value: '25',
          icon: 'small',
        },
        {
          name: 'resizeImage:50',
          value: '50',
          icon: 'medium',
        },
        {
          name: 'resizeImage:75',
          value: '75',
          icon: 'large',
        },
      ],
    })
  }

  init() {
    const editor = this.editor
    const resizeImageCommand = new QrResizeCommand(editor)

    this._registerSchema()
    this._registerConverters('template-field')
    this._registerConverters('type-field')
    this._registerConverters('master-field')

    // Register `resizeImage` command and add `imageResize` command as an alias for backward compatibility.
    editor.commands.add('resizeImage', resizeImageCommand)
    editor.commands.add('imageResize', resizeImageCommand)
  }

  _registerSchema() {
    if (this.editor.plugins.has('TemplateField')) {
      this.editor.model.schema.extend('template-field', {
        allowAttributes: ['width', 'style'],
      })
    }
    if (this.editor.plugins.has('TypeField')) {
      this.editor.model.schema.extend('type-field', {
        allowAttributes: ['width', 'style'],
      })
    }
    if (this.editor.plugins.has('MasterField')) {
      this.editor.model.schema.extend('master-field', {
        allowAttributes: ['width', 'style'],
      })
    }
  }

  _registerConverters(imageType) {
    const editor = this.editor

    // Dedicated converter to propagate image's attribute to the img tag.
    editor.conversion.for('downcast').add(dispatcher =>
      dispatcher.on(
        `attribute:width:${imageType}`,
        (evt, data, conversionApi) => {
          if (!conversionApi.consumable.consume(data.item, evt.name)) {
            return
          }

          const viewWriter = conversionApi.writer
          const figure = conversionApi.mapper.toViewElement(data.item)

          if (data.attributeNewValue !== null) {
            viewWriter.setStyle('width', data.attributeNewValue, figure)
            viewWriter.addClass('image_resized', figure)
          } else {
            viewWriter.removeStyle('width', figure)
            viewWriter.removeClass('image_resized', figure)
          }
        }
      )
    )

    editor.conversion.for('upcast').attributeToAttribute({
      view: {
        name: 'img',
        styles: {
          width: /.+/,
        },
      },
      model: {
        key: 'width',
        value: viewElement => viewElement.getStyle('width'),
      },
    })
  }
}

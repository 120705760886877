import { computed, watch } from '@vue/composition-api'
import { useTypesQuery } from '@/store/types'

export const useType = ({ typeId, typeType }) => {
  const {
    data: typeData,
    refetch,
    isLoading,
  } = useTypesQuery.detail({
    typeId,
    options: {
      enabled: !!typeId?.value,
    },
  })
  const { data: typeByTypeData } = useTypesQuery.typeByType({
    typeType,
    options: {
      enabled: !!typeType?.value,
    },
  })

  const typeByType = computed(() => typeByTypeData.value?.type || {})
  const type = computed(() => typeData.value?.type || {})
  const isAgreementType = computed(() => type.value.type === 'agreement')

  watch(
    () => typeId?.value,
    async () => {
      if (typeId.value) {
        await refetch.value()
      }
    }
  )

  return {
    type,
    typeByType,
    isAgreementType,
    typeRefetch: refetch,
    isLoadingType: isLoading,
  }
}

import Plugin from '@ckeditor/ckeditor5-core/src/plugin'

import FieldEditing from './editing'
import FieldUI from './ui'

export default class AdditionalCustomersField extends Plugin {
  static get requires() {
    return [FieldEditing, FieldUI]
  }
}

<template>
  <span :id="`input-field-${field.id}`" class="d-block">
    <vue-qr
      class="qr-image"
      :text="model"
      :margin="5"
      :correctLevel="3"
      :size="300"
    />
    <b-popover
      v-if="!field.disabled"
      :target="`input-field-${field.id}`"
      :title="$t('forms.labels.qrCode')"
      triggers="click"
      placement="top"
      custom-class="field-popover"
      ref="popover"
      @shown="e => e.relatedTarget.focus()"
    >
      <FormInputGroup
        v-model="model"
        :errors="v$.model.$errors"
        :label="$t('forms.labels.fieldValue')"
        @input="inputHandler"
        @paste="inputHandler"
      />
    </b-popover>
  </span>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { BFormInput, BPopover } from 'bootstrap-vue'
import {
  ref,
  computed,
  onMounted,
  getCurrentInstance,
} from '@vue/composition-api'
import { required } from '@vuelidate/validators'
import { FormInputGroup } from '@/views/components/forms'
import VueQr from 'vue-qr'

export default {
  components: { BFormInput, BPopover, FormInputGroup, VueQr },
  props: {
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    editable: {
      type: Boolean,
      default: false,
    },
    field: {
      type: Object,
      default: () => ({
        defaultValue: '',
        id: '',
        value: null,
        disabled: false,
      }),
    },
  },
  setup(props, { emit }) {
    const model = ref(props.value)

    const inputHandler = value => {
      v$.value.$touch()
      isValid.value && emit('update:value', value)
    }

    const rules = {
      model: { required, $autoDirty: true },
    }
    const v$ = useVuelidate(rules, { model })
    const isValid = computed(() => !v$.value.$error)
    const isValidField = computed(() => !!model.value)

    onMounted(() => {
      const root = getCurrentInstance()?.proxy?.$root
      const el = document.querySelector('#app')
      function hidePopover() {
        root?.$emit('bv::hide::popover')
      }
      if (root) {
        root.$on('bv::popover::show', () => {
          el.addEventListener('click', hidePopover)
        })
        root.$on('bv::popover::hide', () => {
          el.removeEventListener('click', hidePopover)
        })
      }
    })

    return {
      v$,
      model,
      isValidField,
      inputHandler,
    }
  },
}
</script>

<style lang="scss">
.editor {
  &__qr {
    display: inline;
    outline: 0;
    position: relative;
    transition: all 0.3s;
    cursor: pointer !important;
    background: rgba(115, 103, 240, 0.3);
    //.qr-field-invalid {
    //  border-bottom: 1px solid rgba(220, 53, 69, 0.5);
    //}
  }
}

.qr-image {
  width: 100%;
}
@media print {
  .editor {
    &__qr {
      background: transparent;
      border: 0;
      padding: 0;
      outline: 0;
    }
  }
}
</style>

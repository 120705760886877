<template>
  <Loader v-if="isLoading" />
  <div v-else>
    <b-card>
      <template #header>
        <div class="d-flex flex-wrap w-100">
          <h4 class="card-title mr-auto">
            {{ $t(`pages.templates.sections.main`) }}
          </h4>
          <feather-icon
            icon="PrinterIcon"
            size="24"
            class="mr-1 text-primary cursor-pointer"
            @click="() => printHandler(templateModel.html)"
          />
        </div>
      </template>

      <b-row>
        <b-col md="6" lg="4" xl="3">
          <FormInputGroup
            v-model="templateModel.name"
            :errors="v$.templateModel.name.$errors"
            :label="$t('forms.labels.name')"
          />
        </b-col>
        <b-col md="6" lg="4" xl="3">
          <FormSelectTypeGroup
            :key="templateModel.typeId"
            :value.sync="templateModel.typeId"
            :errors="v$.templateModel.typeId.$errors"
            :label="$t('forms.labels.type')"
          />
        </b-col>
        <b-col md="6" lg="4" xl="3">
          <div class="d-flex gap-05 align-items-end">
            <FormSelectGroup
              :value.sync="templateModel.customerType"
              :errors="v$.templateModel.customerType.$errors"
              :options="
                customersTypeOptions.map(t => ({
                  ...t,
                  label: $t(`pages.customers.${t.label}`),
                }))
              "
              :label="$t('forms.labels.customerType', { name: 1 })"
              class="flex-1"
            />
            <b-button
              :disabled="additionalCustomersLength > 3"
              v-ripple
              variant="primary"
              size="sm"
              @click.stop="addCustomerHandler"
              class="mb-1"
            >
              <feather-icon icon="PlusIcon" size="22" />
            </b-button>
          </div>
        </b-col>
        <b-col
          md="6"
          lg="4"
          xl="3"
          v-for="[key, values] in additionalCustomersEntries"
          :key="key"
        >
          <div class="d-flex align-items-center">
            <FormSelectGroup
              :value.sync="values.type"
              :options="
                customersTypeOptions.map(t => ({
                  ...t,
                  label: $t(`pages.customers.${t.label}`),
                }))
              "
              :label="
                $t('forms.labels.customerType', { name: values.customerName })
              "
              class="flex-1"
              @change="
                () => {
                  updaterKey++
                }
              "
            />
            <feather-icon
              class="text-danger ml-05 cursor-pointer"
              icon="TrashIcon"
              size="18"
              @click="() => removeCustomerHandler(key)"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="8" lg="6">
          <FormTextareaGroup
            :value.sync="templateModel.comment"
            :label="$t('forms.labels.comment')"
          />
        </b-col>
      </b-row>
    </b-card>

    <Editor
      v-if="isEditorView"
      :content-data.sync="templateModel.html"
      :template-fields="templateModel.fields"
      :type-fields="templateModel.typeFields"
      :master-fields="templateModel.masterFields"
      :customer-fields="customerFields"
      :additional-customers="additionalCustomers"
      :editable="editable"
      :type="type"
      :agreement-type="agreementType"
      :key="updaterKey"
      @add:field="addField"
      @update:field="updateField"
      @delete:field="deleteField"
    />
    <ActionButtons
      :isPrint="true"
      :isEdit="isEdit"
      @save="saveHandler"
      @print="() => printHandler(templateModel.html)"
      @saveStay="() => saveHandler(true)"
    />
  </div>
</template>

<script>
import router from '@/router'
import { ref, reactive, computed, watch } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BInputGroupAppend,
  BCardHeader,
  BCardBody,
} from 'bootstrap-vue'
import {
  FormInputGroup,
  FormSelectGroup,
  FormSelectTypeGroup,
  FormSwitchGroup,
  FormTextareaGroup,
} from '@/views/components/forms'
import { useTemplatesQuery } from '@/store/templates'
import { personFields, legalFields } from '@/utils/config'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { customersTypeOptions } from '@/utils/config'
import { required } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import Editor from '@/views/components/editor/Editor_v2.vue'
import useToast from '@/views/components/useToast'
import ActionButtons from '@/views/components/pages/ActionButtons.vue'
import { getRandomId, printHandler } from '@/utils/helpers'
import { useType } from '@/models/useType'

export default {
  components: {
    FormSwitchGroup,
    FormSelectTypeGroup,
    BCardBody,
    BCardHeader,
    BInputGroupAppend,
    Editor,
    BRow,
    BCol,
    BButton,
    BCard,
    FormInputGroup,
    FormSelectGroup,
    FormTextareaGroup,
    ActionButtons,
  },
  props: {
    templateId: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { t } = useI18nUtils()
    const toast = useToast()
    const editable = ref(true)
    const updaterKey = ref(0)
    const isEdit = computed(() => !!props.templateId)
    const isEditorView = computed(
      () =>
        !!templateModel.typeId &&
        !!templateModel.customerType &&
        !isLoadingType.value
    )
    const templateId = computed(() => props.templateId || '')
    const isStay = ref(false)

    const toastsText = {
      edit: t('notifications.success.templates.edit'),
      create: t('notifications.success.templates.create'),
    }

    let templateModel = reactive({
      html: '',
      name: '',
      fields: [],
      typeFields: [],
      masterFields: [],
      typeId: '',
      customerType: '',
      customerFields: [],
      additionalCustomers: {},
      comment: '',
    })

    const { typeByType: agreementType } = useType({
      typeType: ref('agreement'),
    })

    const { type, isLoadingType } = useType({
      typeId: computed(() => templateModel.typeId),
    })

    const customerFields = computed(() =>
      templateModel.customerType === 'person'
        ? getFullDataFields(personFields)
        : getFullDataFields(legalFields)
    )

    const getFullDataFields = fields =>
      fields.map(field => {
        return {
          ...field,
          type: 'customerField',
          ids:
            templateModel.customerFields
              ?.filter(_f => _f.key === field.key)
              ?.map(_f => _f.id) || [],
        }
      })

    const additionalCustomersEntries = computed(() =>
      Object.entries(templateModel.additionalCustomers ?? {}).sort(
        ([, a], [, b]) => {
          if (Number(a.customerName) > Number(b.customerName)) return 0
          return -1
        }
      )
    )
    const additionalCustomersLength = computed(
      () => Object.keys(templateModel.additionalCustomers ?? {}).length
    )

    const additionalCustomers = computed(() => {
      const customers = Object.entries(templateModel.additionalCustomers).map(
        ([customerKey, { type, customerName }]) => [
          customerKey,
          {
            customerKey,
            customerName,
            type,
            fields: getFullDataAdditionalCustomersFields(
              type === 'person' ? personFields : legalFields,
              customerKey
            ),
          },
        ]
      )
      return Object.fromEntries(customers)
    })

    const getFullDataAdditionalCustomersFields = (fields, customerKey) =>
      fields.map(field => ({
        ...field,
        type: 'additionalCustomersField',
        ids:
          templateModel.additionalCustomers[customerKey].fields
            ?.filter(_f => _f.key === field.key)
            ?.map(_f => _f.id) || [],
      }))

    const getFieldKey = type => {
      if (type === 'typeField') return 'typeFields'
      else if (type === 'customerField') return 'customerFields'
      else if (type === 'masterField') return 'masterFields'
      else if (type === 'additionalCustomersField') return 'additionalCustomers'
      return 'fields'
    }

    const updateField = field => {
      const key = getFieldKey(field.type)
      if (field.type === 'additionalCustomersField') {
        templateModel[key][field.customerKey].fields = templateModel[key][
          field.customerKey
        ].fields.map(f => (f.id === field.id ? { ...f, ...field } : f))
        return
      }

      templateModel[key] = templateModel[key].map(f =>
        f.id === field.id ? { ...f, ...field } : f
      )
    }

    const deleteField = field => {
      const key = getFieldKey(field.type)
      if (field.type === 'additionalCustomersField') {
        templateModel[key][field.customerKey].fields.filter(
          f => f.id !== field.id
        )
        return
      }
      templateModel[key] = templateModel[key].filter(f => f.id !== field.id)
    }

    const addField = field => {
      const key = getFieldKey(field.type)
      if (field.type === 'additionalCustomersField') {
        templateModel[key][field.customerKey]?.fields.push(field)
        return
      }
      templateModel[key].push(field)
    }

    const checkFields = async () => {
      const fieldsKeys = [
        'fields',
        'typeFields',
        'masterFields',
        'customerFields',
      ]
      for (const key of fieldsKeys) {
        templateModel[key] =
          (await templateModel[key]?.filter(field =>
            templateModel.html.includes(`editor-container-id="${field.id}"`)
          )) || []
      }

      for (const customerKey of Object.keys(
        templateModel.additionalCustomers
      )) {
        templateModel.additionalCustomers[customerKey].fields =
          (await templateModel.additionalCustomers[customerKey].fields?.filter(
            field =>
              templateModel.html.includes(`editor-container-id="${field.id}"`)
          )) || []
      }
    }

    const getCustomerName = name => {
      const customerNames = new Array(4).fill(1).map((v, i) => i + 2)
      const addCustomers = Object.values(templateModel.additionalCustomers)
      const addCustomersNames = new Set(
        addCustomers.map(({ customerName }) => customerName)
      )
      for (name of customerNames) {
        if (addCustomersNames.has(name)) {
          continue
        }
        return name
      }
      return 0
    }

    const addCustomerHandler = () => {
      const customerKey = getRandomId()
      const customerName = getCustomerName()
      templateModel.additionalCustomers[customerKey] = {
        type: 'person',
        fields: [],
        customerKey,
        customerName,
      }
      templateModel.additionalCustomers = {
        ...templateModel.additionalCustomers,
      }
    }

    const removeCustomerHandler = key => {
      delete templateModel.additionalCustomers[key]
      templateModel.additionalCustomers = {
        ...templateModel.additionalCustomers,
      }
    }

    const { isSuccess, isLoading, isFetching } = useTemplatesQuery.detail({
      templateId,
      options: {
        enabled: computed(() => isEdit.value).value,
        onSuccess: data => {
          if (data.success) {
            templateModel = Object.assign(templateModel, data.template)
          }
        },
        refetchOnMount: 'always',
      },
    })

    const { mutate: onCreate } = useTemplatesQuery.create({
      template: templateModel,
      options: {
        onSuccess(data) {
          if (data.success) {
            toast.success(toastsText.create)
            if (!isStay.value) {
              router.push({ name: 'templates' })
            } else {
              router.push({
                name: 'template_edit',
                params: { templateId: data.template._id },
              })
            }
          } else {
            toast.error(JSON.stringify(data.error))
          }
        },
      },
    })

    const { mutate: onUpdate } = useTemplatesQuery.update({
      templateId,
      template: templateModel,
      options: {
        onSuccess(data) {
          if (data.success) {
            toast.success(toastsText.edit)
            if (!isStay.value) {
              router.push({ name: 'templates' })
              return
            }
            templateModel = Object.assign(templateModel, data.template)
          } else {
            toast.error(JSON.stringify(data.error))
          }
        },
      },
    })

    const saveHandler = async isStayHere => {
      v$.value.$touch()
      if (!isValid.value) return
      isStay.value = !!isStayHere
      await checkFields()
      isEdit.value ? onUpdate() : onCreate()
    }

    const rules = {
      templateModel: {
        name: { required, $autoDirty: true },
        typeId: { required, $autoDirty: true },
        customerType: { required, $autoDirty: true },
      },
    }
    const v$ = useVuelidate(rules, { templateModel })
    const isValid = computed(() => !v$.value.$error)

    watch(
      () => templateModel.customerType,
      () => {
        updaterKey.value++
      }
    )
    watch(
      () => templateModel.typeId,
      () => {
        updaterKey.value++
      }
    )
    watch(
      () => additionalCustomersLength.value,
      () => {
        updaterKey.value++
      }
    )

    return {
      v$,
      editable,
      templateModel,

      customersTypeOptions,
      customerFields,

      additionalCustomers,
      additionalCustomersEntries,
      additionalCustomersLength,

      type,
      agreementType,

      personFields,
      legalFields,

      isEdit,
      isLoading,
      isSuccess,
      isFetching,
      isEditorView,

      updaterKey,

      saveHandler,
      printHandler,
      addField,
      updateField,
      deleteField,
      getFullDataFields,
      addCustomerHandler,
      removeCustomerHandler,
    }
  },
}
</script>

import { Plugin } from 'ckeditor5/src/core'
import QrResizerButtons from './buttons'
import QrResizerEditing from './editing'
import QrResizerHandles from './handles'

export default class QrResizer extends Plugin {
  static get requires() {
    return [QrResizerEditing, QrResizerHandles, QrResizerButtons]
  }
  static get pluginName() {
    return 'QrResizer'
  }
}

<template>
  <div class="d-flex mt-50 position-sticky zindex-4" style="bottom: 15px">
    <b-button
      v-if="isPrint"
      v-ripple
      variant="primary"
      class="mr-1"
      @click="() => $emit('print')"
    >
      <feather-icon icon="PrinterIcon" size="18" />
    </b-button>

    <b-button
      v-if="isJournal"
      v-ripple
      variant="primary"
      class="mr-auto"
      @click="() => $emit('journal')"
    >
      <feather-icon icon="TruckIcon" size="18" />
    </b-button>

    <b-button
      v-ripple
      variant="primary"
      class="ml-auto"
      @click="() => $emit('saveStay')"
    >
      <feather-icon icon="SaveIcon" size="18" />
    </b-button>

    <b-button
      v-ripple
      variant="outline-secondary"
      class="ml-1"
      @click="() => (confirmModal = true)"
    >
      <feather-icon icon="LogOutIcon" size="18" />
    </b-button>

    <ModalConfirmSave
      :show="confirmModal"
      :text="$t('modals.texts.save')"
      @success="
        () => {
          confirmModal = false
          $emit('save')
        }
      "
      @cancel="
        () => {
          confirmModal = false
          $router.go(-1)
        }
      "
      @update:show="state => (confirmModal = state)"
    />
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import ModalConfirmSave from '@/views/components/modals/ModalConfirmSave.vue'
import { ref } from '@vue/composition-api'

export default {
  emits: ['save', 'saveStay'],
  components: { BButton, ModalConfirmSave },
  props: {
    isPrint: {
      type: Boolean,
      default: false,
    },
    isJournal: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const confirmModal = ref(false)
    return {
      confirmModal,
    }
  },
}
</script>

<template>
  <b-modal
    v-model="open"
    :ok-title="$t('forms.buttons.save')"
    :title="$t('modals.titles.confirm')"
    :cancel-title="$t('forms.buttons.exit')"
    ok-variant="primary"
    modal-class="modal-primary"
    centered
    @ok="onSuccess"
    @cancel="onCancel"
  >
    <span>{{ text || $t('modals.texts.default') }}</span>
  </b-modal>
</template>

<script>
import { BModal, VBModal } from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'

export default {
  emits: ['update:show', 'success', 'cancel'],
  components: {
    BModal,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    text: {
      type: String,
      default: () => '',
    },
  },
  directives: {
    'b-modal': VBModal,
  },
  setup(props, { emit }) {
    const open = ref(props.show)

    const onSuccess = () => {
      emit('success')
    }
    const onCancel = () => {
      emit('cancel')
    }

    watch(
      () => props.show,
      () => {
        open.value = props.show
      }
    )

    watch(
      () => open.value,
      () => {
        emit('update:show', open.value)
      }
    )

    return {
      open,
      onSuccess,
      onCancel,
    }
  },
}
</script>

<template>
  <ul
    :contenteditable="false"
    :id="`list-field-${field.id}`"
    class="mb-0"
    :class="isValidField ? '' : 'list-field-invalid'"
  >
    <li v-if="model.length === 0">
      {{ $t('editor.selectValue') }}
    </li>
    <li v-for="item in model">
      {{ item }}
    </li>

    <b-popover
      v-if="!field.disabled"
      :target="`list-field-${field.id}`"
      :title="$t('forms.labels.list')"
      triggers="click"
      placement="top"
      custom-class="field-popover list-field-popover"
      @shown="e => e.relatedTarget.focus()"
    >
      <FormInputGroup
        v-if="editorType === 'editor'"
        v-model="optionModel"
        :label="$t('forms.labels.addListItem')"
      >
        <template #append>
          <b-button
            v-ripple
            variant="primary"
            :disabled="!optionModel"
            @click="addOption"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>
        </template>
      </FormInputGroup>

      <b-list-group>
        <b-list-group-item
          v-for="(option, _i) in options"
          :key="option.label"
          :active="model.includes(option.value)"
          class="d-flex justify-content-between align-items-center cursor-pointer"
          @click="() => inputHandler(option.value)"
        >
          <span class="font-small-2">{{ option.label }}</span>
          <b-button
            v-if="editorType === 'editor'"
            v-ripple
            variant="danger"
            size="sm"
            class="field__delete btn-icon p-0"
            @click="deleteHandler(option.value, _i)"
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </b-list-group-item>
      </b-list-group>
    </b-popover>
  </ul>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {
  ref,
  computed,
  onMounted,
  getCurrentInstance,
} from '@vue/composition-api'
import {
  BFormInput,
  BPopover,
  BButton,
  BRow,
  BCol,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import { required } from '@vuelidate/validators'
import { FormInputGroup, FormSelectGroup } from '@/views/components/forms'

export default {
  components: {
    BFormInput,
    BPopover,
    BButton,
    BRow,
    BCol,
    FormSelectGroup,
    FormInputGroup,
    BListGroup,
    BListGroupItem,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'text',
    },
    field: {
      type: Object,
      default: () => ({
        defaultValue: '',
        id: '',
        value: null,
        disabled: false,
      }),
    },
    editorType: {
      type: String,
      default: 'viewer',
    },
  },
  setup(props, { emit }) {
    const model = ref(props.value)
    const optionModel = ref('')
    const options = ref(props.field.options || [])

    const addOption = () => {
      v$.value.$touch()
      if (!isValid.value) return
      options.value.push({ value: optionModel.value, label: optionModel.value })
      emit('update:field', { ...props.field, options: options.value })
    }

    const deleteHandler = (value, i) => {
      model.value = model.value.filter(val => val !== value)
      options.value.splice(i, 1)

      emit('update:field', {
        ...props.field,
        options: options.value,
        defaultValue: model.value,
      })
    }

    const inputHandler = value => {
      model.value = model.value.includes(value)
        ? model.value.filter(val => val !== value)
        : [...model.value, value]

      if (props.editorType === 'viewer') {
        emit('update:field', {
          ...props.field,
          options: options.value,
          value: model.value,
        })
      } else {
        emit('update:field', {
          ...props.field,
          options: options.value,
          defaultValue: model.value,
        })
      }
    }
    const rules = {
      optionModel: {
        required,
        duplicate: value => !options.value.some(opt => opt.value === value),
        $autoDirty: true,
      },
    }
    const v$ = useVuelidate(rules, { optionModel })
    const isValid = computed(() => !v$.value.$error)
    const isValidField = computed(() => !!model.value.length > 0)

    onMounted(() => {
      const root = getCurrentInstance()?.proxy?.$root
      const el = document.querySelector('#app')
      function hidePopover() {
        root?.$emit('bv::hide::popover')
      }
      if (root) {
        root.$on('bv::popover::show', () => {
          el.addEventListener('click', hidePopover)
        })
        root.$on('bv::popover::hide', () => {
          el.removeEventListener('click', hidePopover)
        })
      }
    })
    return {
      v$,
      model,
      options,
      optionModel,
      isValidField,
      inputHandler,
      deleteHandler,
      addOption,
    }
  },
}
</script>

<style lang="scss">
.list-field-popover {
  max-width: 500px;

  .popover-body {
    overflow: auto;
    max-height: 400px;
  }
}
.editor {
  &__list {
    cursor: pointer !important;
    display: block;
    width: 100%;
    outline: 0;
    transition: all 0.3s;
    background: rgba(115, 103, 240, 0.3);

    .list-field-invalid {
      border-bottom: 1px solid rgba(220, 53, 69, 0.5);
    }
  }
}

@media print {
  .editor {
    &__list {
      background: transparent;
      border: 0;
      padding: 0;
      outline: 0;
    }
  }
}
</style>

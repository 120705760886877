<template>
  <ul
    v-if="innerType === 'list'"
    :contenteditable="false"
    :ref="field.id"
    :id="`type-field-${field.id}`"
    class="mb-0"
    :class="isValidField ? '' : 'type-field-invalid'"
  >
    <li v-if="viewValue.length === 0">
      {{ $t('editor.selectValue') }}
    </li>
    <li v-for="item in viewValue">
      {{ item }}
    </li>

    <b-popover
      v-if="editorType === 'editor'"
      :target="`type-field-${field.id}`"
      :title="$t('forms.labels.selectOption')"
      :variant="field.type === 'typeField' ? 'success' : 'warning'"
      triggers="click"
      placement="top"
      custom-class="field-popover"
      @shown="e => e.relatedTarget.focus()"
    >
      <FormSelectGroup
        :value.sync="model"
        :options="
          options.map(t => ({
            ...t,
            label: !!t.label ? $t(`forms.labels.${t.label}`) : t.name || '',
          }))
        "
        :label="$t('forms.labels.selectOption')"
        @change="changeHandler"
      />
    </b-popover>
  </ul>

  <span
    v-else-if="innerType === 'qr'"
    :contenteditable="false"
    :ref="field.id"
    :id="`type-field-${field.id}`"
    class="d-block"
  >
    <vue-qr
      v-if="viewValue"
      class="qr-image"
      :text="viewValue"
      :margin="5"
      :correctLevel="3"
      :size="300"
    />

    <b-popover
      v-if="editorType === 'editor'"
      :target="`type-field-${field.id}`"
      :title="$t('forms.labels.selectOption')"
      :variant="field.type === 'typeField' ? 'success' : 'warning'"
      triggers="click"
      placement="top"
      custom-class="field-popover"
      @shown="e => e.relatedTarget.focus()"
    >
      <FormSelectGroup
        :value.sync="model"
        :options="
          options.map(t => ({
            ...t,
            label: !!t.label ? $t(`forms.labels.${t.label}`) : t.name || '',
          }))
        "
        :label="$t('forms.labels.selectOption')"
        @change="changeHandler"
      />
    </b-popover>
  </span>

  <span
    v-else
    :contenteditable="false"
    :ref="field.id"
    :id="`type-field-${field.id}`"
    :class="isValidField ? '' : 'type-field-invalid'"
  >
    <span>{{ viewValue || $t('editor.selectValue') }}</span>

    <b-popover
      v-if="editorType === 'editor'"
      :target="`type-field-${field.id}`"
      :title="$t('forms.labels.selectOption')"
      :variant="field.type === 'typeField' ? 'success' : 'warning'"
      triggers="click"
      placement="top"
      custom-class="field-popover"
      @shown="e => e.relatedTarget.focus()"
    >
      <FormSelectGroup
        :value.sync="model"
        :options="
          options.map(t => ({
            ...t,
            label: !!t.label ? $t(`forms.labels.${t.label}`) : t.name || '',
          }))
        "
        :label="$t('forms.labels.selectOption')"
        @change="changeHandler"
      />
    </b-popover>
  </span>
</template>

<script>
import moment from 'moment'
import {
  ref,
  computed,
  onMounted,
  getCurrentInstance,
} from '@vue/composition-api'
import { BFormInput, BPopover, BButton, BRow, BCol } from 'bootstrap-vue'
import { FormInputGroup, FormSelectGroup } from '@/views/components/forms'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import VueQr from 'vue-qr'

export default {
  components: {
    BFormInput,
    BPopover,
    BButton,
    BRow,
    BCol,
    FormSelectGroup,
    FormInputGroup,
    VueQr,
  },
  props: {
    value: {
      type: [String, Array],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    field: {
      type: Object,
      default: () => ({
        defaultValue: '',
        id: '',
        value: null,
      }),
    },
    editorType: {
      type: String,
      default: 'viewer',
    },
  },
  setup(props, { emit }) {
    const { locale } = useI18nUtils()
    const modelValue = ref(props.value)
    const field = ref(props.field)
    const model = ref(field.value.key)
    const options = ref(field.value.options || [])
    const currentLocale = computed(() => locale())

    const viewField = computed(
      () => options.value.find(opt => opt.value === model.value) || {}
    )
    const innerType = computed(() => viewField.value.innerType)
    const format = computed(() => viewField.value.format)
    const innerOptions = computed(() => viewField.value.innerOptions)
    const isValidField = computed(
      () => field.value.options.length > 0 && !!viewValue.value
    )

    const viewValue = computed(() => {
      if (innerType.value === 'select') {
        let option = innerOptions.value.find(
          opt => opt.value === modelValue.value
        )
        if (!option) {
          option = innerOptions.value.find(
            opt => opt.value === viewField.value.defaultValue
          )
        }
        return option?.label || option?.value || ''
      }

      if (innerType.value === 'list') {
        return modelValue.value || viewField.value.defaultValue || []
      }

      if (innerType.value === 'date') {
        return moment(
          modelValue.value || viewField.value.defaultValue || new Date()
        )
          .locale(currentLocale.value)
          .format(format.value)
      }

      return modelValue.value || viewField.value.defaultValue?.toString()
    })

    const updateValue = value => {
      modelValue.value = value
      changeHandler(value)
    }

    const changeHandler = value => {
      emit('update:field', {
        id: field.value.id,
        key: value,
        type: field.value.type,
      })
    }

    onMounted(() => {
      const root = getCurrentInstance()?.proxy?.$root
      const el = document.querySelector('#app')
      function hidePopover() {
        root?.$emit('bv::hide::popover')
      }
      if (root) {
        root.$on('bv::popover::show', () => {
          el.addEventListener('click', hidePopover)
        })
        root.$on('bv::popover::hide', () => {
          el.removeEventListener('click', hidePopover)
        })
      }
    })

    return {
      model,
      viewValue,
      options,
      innerType,
      isValidField,
      changeHandler,
      updateValue,
    }
  },
}
</script>

<style lang="scss">
.editor {
  &__typeField {
    outline: 0;
    transition: all 0.3s;
    cursor: pointer !important;
    background: rgba(40, 199, 111, 0.3);

    &:hover {
      background: rgba(40, 199, 111, 0.3);
    }

    ul {
      background: rgba(40, 199, 111, 0.3);
    }

    .type-field-invalid {
      border-bottom: 1px solid rgba(220, 53, 69, 0.5);
    }
  }
  &__masterField {
    outline: 0;
    transition: all 0.3s;
    cursor: pointer !important;
    background: rgba(255, 159, 67, 0.3);

    ul {
      background: rgba(255, 159, 67, 0.3);
    }

    .type-field-invalid {
      border-bottom: 1px solid rgba(220, 53, 69, 0.5);
    }
  }
}

@media print {
  .editor {
    &__typeField,
    &__masterField {
      background: transparent;
      border: 0;
      padding: 0;
      outline: 0;
    }
  }
}
</style>

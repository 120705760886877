import Plugin from '@ckeditor/ckeditor5-core/src/plugin'
import {
  toWidget,
  viewToModelPositionOutsideModelElement,
} from '@ckeditor/ckeditor5-widget/src/utils'
import Widget from '@ckeditor/ckeditor5-widget/src/widget'

import FieldCommand from './command'

export default class FieldEditing extends Plugin {
  static get requires() {
    return [Widget]
  }

  init() {
    this._defineSchema()
    this._defineConverters()

    this.editor.commands.add(
      'additional-customers-field',
      new FieldCommand(this.editor)
    )

    // Разобраться что это
    this.editor.editing.mapper.on(
      'viewToModelPosition',
      viewToModelPositionOutsideModelElement(this.editor.model, viewElement =>
        viewElement.hasClass('additional-customers-field')
      )
    )
  }

  _defineSchema() {
    const schema = this.editor.model.schema

    schema.register('additional-customers-field', {
      // Allow wherever text is allowed:
      allowWhere: '$text',
      // The textfield will act as an inline node:
      isInline: true,
      // The inline widget is self-contained so it cannot be split by the caret and it can be selected:
      isObject: true,
      // The inline widget can have the same attributes as text (for example linkHref, bold).
      allowAttributesOf: '$text',
      // The textfield can have many types, like date, name, surname, etc:
      allowAttributes: [
        'editor-container-id',
        'editor-container-type',
        'editor-container-customer-key',
        'editor-container-customer-name',
      ],
    })
  }

  _defineConverters() {
    const conversion = this.editor.conversion

    conversion.for('upcast').elementToElement({
      view: {
        name: 'span',
        classes: ['additional-customers-field'],
      },
      model: (viewElement, { writer: viewWriter }) => {
        const id = viewElement.getAttribute('editor-container-id')
        const type = viewElement.getAttribute('editor-container-type')
        const customerKey = viewElement.getAttribute(
          'editor-container-customer-key'
        )
        const customerName = viewElement.getAttribute(
          'editor-container-customer-name'
        )
        return viewWriter.createElement('additional-customers-field', {
          'editor-container-type': type,
          'editor-container-id': id,
          'editor-container-customer-key': customerKey,
          'editor-container-customer-name': customerName,
        })
      },
    })

    conversion.for('dataDowncast').elementToElement({
      model: 'additional-customers-field',
      view: (modelItem, { writer: viewWriter }) => {
        const id = modelItem.getAttribute('editor-container-id')
        const type = modelItem.getAttribute('editor-container-type')
        const customerKey = modelItem.getAttribute(
          'editor-container-customer-key'
        )
        const customerName = modelItem.getAttribute(
          'editor-container-customer-name'
        )
        return viewWriter.createContainerElement('span', {
          class: `additional-customers-field`,
          'editor-container-id': id,
          'editor-container-type': type,
          'editor-container-customer-key': customerKey,
          'editor-container-customer-name': customerName,
        })
      },
    })

    conversion.for('editingDowncast').elementToElement({
      model: 'additional-customers-field',
      view: (modelItem, { writer: viewWriter }) => {
        const widgetElement = createTextFieldView(modelItem, viewWriter)
        return toWidget(widgetElement, viewWriter)
      },
    })

    const renderField = this.editor.config.get('fields').render

    // Helper method for both downcast converters.
    const createTextFieldView = (modelItem, viewWriter) => {
      const type = modelItem.getAttribute('editor-container-type')
      const id = modelItem.getAttribute('editor-container-id')
      const customerKey = modelItem.getAttribute(
        'editor-container-customer-key'
      )
      const customerName = modelItem.getAttribute(
        'editor-container-customer-name'
      )

      const field = { type, id, customerKey, customerName }
      const fieldView = viewWriter.createContainerElement('span', {
        class: `additional-customers-field`,
        'editor-container-id': field.id,
        'editor-container-type': field.type,
        'editor-container-customer-key': field.customerKey,
        'editor-container-customer-name': field.customerName,
      })

      const vueWrappers = viewWriter.createRawElement(
        'span',
        {
          class: `editor__${field.type}`,
          'editor-id': field.id,
          'editor-type': field.type,
          'editor-customer-key': field.customerKey,
          'editor-customer-name': field.customerName,
        },

        function (domElement) {
          renderField({ id, type, domElement, customerKey, customerName })
        }
      )

      viewWriter.insert(viewWriter.createPositionAt(fieldView, 0), vueWrappers)

      return fieldView
    }
  }
}

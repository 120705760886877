var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('Loader'):_c('div',[_c('b-card',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex flex-wrap w-100"},[_c('h4',{staticClass:"card-title mr-auto"},[_vm._v(" "+_vm._s(_vm.$t("pages.templates.sections.main"))+" ")]),_c('feather-icon',{staticClass:"mr-1 text-primary cursor-pointer",attrs:{"icon":"PrinterIcon","size":"24"},on:{"click":function () { return _vm.printHandler(_vm.templateModel.html); }}})],1)]},proxy:true}])},[_c('b-row',[_c('b-col',{attrs:{"md":"6","lg":"4","xl":"3"}},[_c('FormInputGroup',{attrs:{"errors":_vm.v$.templateModel.name.$errors,"label":_vm.$t('forms.labels.name')},model:{value:(_vm.templateModel.name),callback:function ($$v) {_vm.$set(_vm.templateModel, "name", $$v)},expression:"templateModel.name"}})],1),_c('b-col',{attrs:{"md":"6","lg":"4","xl":"3"}},[_c('FormSelectTypeGroup',{key:_vm.templateModel.typeId,attrs:{"value":_vm.templateModel.typeId,"errors":_vm.v$.templateModel.typeId.$errors,"label":_vm.$t('forms.labels.type')},on:{"update:value":function($event){return _vm.$set(_vm.templateModel, "typeId", $event)}}})],1),_c('b-col',{attrs:{"md":"6","lg":"4","xl":"3"}},[_c('div',{staticClass:"d-flex gap-05 align-items-end"},[_c('FormSelectGroup',{staticClass:"flex-1",attrs:{"value":_vm.templateModel.customerType,"errors":_vm.v$.templateModel.customerType.$errors,"options":_vm.customersTypeOptions.map(function (t) { return (Object.assign({}, t,
                {label: _vm.$t(("pages.customers." + (t.label)))})); }),"label":_vm.$t('forms.labels.customerType', { name: 1 })},on:{"update:value":function($event){return _vm.$set(_vm.templateModel, "customerType", $event)}}}),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"mb-1",attrs:{"disabled":_vm.additionalCustomersLength > 3,"variant":"primary","size":"sm"},on:{"click":function($event){$event.stopPropagation();return _vm.addCustomerHandler.apply(null, arguments)}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"22"}})],1)],1)]),_vm._l((_vm.additionalCustomersEntries),function(ref){
              var key = ref[0];
              var values = ref[1];
return _c('b-col',{key:key,attrs:{"md":"6","lg":"4","xl":"3"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('FormSelectGroup',{staticClass:"flex-1",attrs:{"value":values.type,"options":_vm.customersTypeOptions.map(function (t) { return (Object.assign({}, t,
                {label: _vm.$t(("pages.customers." + (t.label)))})); }),"label":_vm.$t('forms.labels.customerType', { name: values.customerName })},on:{"update:value":function($event){return _vm.$set(values, "type", $event)},"change":function () {
                _vm.updaterKey++
              }}}),_c('feather-icon',{staticClass:"text-danger ml-05 cursor-pointer",attrs:{"icon":"TrashIcon","size":"18"},on:{"click":function () { return _vm.removeCustomerHandler(key); }}})],1)])})],2),_c('b-row',[_c('b-col',{attrs:{"md":"8","lg":"6"}},[_c('FormTextareaGroup',{attrs:{"value":_vm.templateModel.comment,"label":_vm.$t('forms.labels.comment')},on:{"update:value":function($event){return _vm.$set(_vm.templateModel, "comment", $event)}}})],1)],1)],1),(_vm.isEditorView)?_c('Editor',{key:_vm.updaterKey,attrs:{"content-data":_vm.templateModel.html,"template-fields":_vm.templateModel.fields,"type-fields":_vm.templateModel.typeFields,"master-fields":_vm.templateModel.masterFields,"customer-fields":_vm.customerFields,"additional-customers":_vm.additionalCustomers,"editable":_vm.editable,"type":_vm.type,"agreement-type":_vm.agreementType},on:{"update:contentData":function($event){return _vm.$set(_vm.templateModel, "html", $event)},"update:content-data":function($event){return _vm.$set(_vm.templateModel, "html", $event)},"add:field":_vm.addField,"update:field":_vm.updateField,"delete:field":_vm.deleteField}}):_vm._e(),_c('ActionButtons',{attrs:{"isPrint":true,"isEdit":_vm.isEdit},on:{"save":_vm.saveHandler,"print":function () { return _vm.printHandler(_vm.templateModel.html); },"saveStay":function () { return _vm.saveHandler(true); }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import Plugin from '@ckeditor/ckeditor5-core/src/plugin'
import { ButtonView } from '@ckeditor/ckeditor5-ui/'

import { getRandomId } from '@/utils/helpers'

export default class FieldUI extends Plugin {
  init() {
    const editor = this.editor

    editor.ui.componentFactory.add('customer-field', locale => {
      const buttonView = new ButtonView(locale)

      buttonView.set({
        tooltip: 'Поле контрагента',
        withText: false,
        class: 'border-info text-info font-small-2 ck-field-button',
        icon: userIcon,
      })

      const command = editor.commands.get('customer-field')
      buttonView.bind('isEnabled').to(command)

      this.listenTo(buttonView, 'execute', evt => {
        editor.execute('customer-field', {
          type: 'customerField',
          id: getRandomId(),
        })
        editor.editing.view.focus()
      })

      return buttonView
    })
  }
}

const userIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>'

import Command from '@ckeditor/ckeditor5-core/src/command'

export default class FieldCommand extends Command {
  execute({ type, id }) {
    const editor = this.editor
    const selection = editor.model.document.selection

    editor.model.change(writer => {
      const field = writer.createElement('type-field', {
        ...Object.fromEntries(selection.getAttributes()),
        'editor-container-type': type,
        'editor-container-id': id,
      })
      editor.model.insertContent(field)

      writer.setSelection(field, 'on')

      this.editor.fire('insertField', { type, id })
    })
  }

  refresh() {
    const model = this.editor.model
    const selection = model.document.selection

    this.isEnabled = model.schema.checkChild(
      selection.focus.parent,
      'type-field'
    )
  }
}

import Plugin from '@ckeditor/ckeditor5-core/src/plugin'
import {
  addListToDropdown,
  createDropdown,
} from '@ckeditor/ckeditor5-ui/src/dropdown/utils'
import Collection from '@ckeditor/ckeditor5-utils/src/collection'
import Model from '@ckeditor/ckeditor5-ui/src/model'

import { getRandomId } from '@/utils/helpers'

export default class FieldUI extends Plugin {
  init() {
    const editor = this.editor
    const customers = this.editor.config.get('fields').additionalCustomers ?? {}

    editor.ui.componentFactory.add('additional-customers-field', locale => {
      const dropdownView = createDropdown(locale)
      // Populate the list in the dropdown with items.
      addListToDropdown(dropdownView, getDropdownItemsDefinitions(customers))

      dropdownView.buttonView.set({
        tooltip: 'Поле доп.контрагента',
        withText: false,
        class: 'border-turquo text-turquo font-small-2 ck-field-button',
        icon: userIcon,
      })

      const command = editor.commands.get('additional-customers-field')
      dropdownView.bind('isEnabled').to(command)

      this.listenTo(dropdownView, 'execute', evt => {
        editor.execute('additional-customers-field', {
          customerKey: evt.source.customerKey,
          customerName: evt.source.customerName,
          type: 'additionalCustomersField',
          id: getRandomId(),
        })
        editor.editing.view.focus()
      })

      return dropdownView
    })
  }
}

function getDropdownItemsDefinitions(customers) {
  const itemDefinitions = new Collection()

  for (const [customerKey, { customerName }] of Object.entries(customers).sort(
    ([, a], [, b]) => {
      if (Number(a.customerName) > Number(b.customerName)) return 0
      return -1
    }
  )) {
    const definition = {
      type: 'button',
      model: new Model({
        customerKey,
        customerName: customerName,
        label: `Контрагент ${customerName}`,
        withText: true,
      }),
    }
    itemDefinitions.add(definition)
  }

  return itemDefinitions
}

const userIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>'

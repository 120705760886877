import Plugin from '@ckeditor/ckeditor5-core/src/plugin'
import Collection from '@ckeditor/ckeditor5-utils/src/collection'
import Model from '@ckeditor/ckeditor5-ui/src/model'
import {
  addListToDropdown,
  createDropdown,
} from '@ckeditor/ckeditor5-ui/src/dropdown/utils'

import { getRandomId } from '@/utils/helpers'

export default class FieldUI extends Plugin {
  init() {
    const editor = this.editor
    const templateFieldTypes = this.editor.config.get('fields').types

    editor.ui.componentFactory.add('template-field', locale => {
      const dropdownView = createDropdown(locale)
      // Populate the list in the dropdown with items.
      addListToDropdown(
        dropdownView,
        getDropdownItemsDefinitions(templateFieldTypes)
      )

      dropdownView.buttonView.set({
        tooltip: 'Поле шаблона',
        withText: false,
        class: 'border-primary text-primary ck-field-button font-small-2',
        icon: fileIcon,
      })

      // Disable the textfield button when the command is disabled.
      const command = editor.commands.get('template-field')
      dropdownView.bind('isEnabled').to(command)

      // Execute the command when the dropdown item is clicked (executed).
      this.listenTo(dropdownView, 'execute', evt => {
        editor.execute('template-field', {
          type: evt.source.commandParam,
          id: getRandomId(),
        })
        editor.editing.view.focus()
      })

      return dropdownView
    })
  }
}

function getDropdownItemsDefinitions(types) {
  const itemDefinitions = new Collection()

  for (const type of types) {
    const definition = {
      type: 'button',
      model: new Model({
        commandParam: type.type,
        label: type.label,
        withText: true,
      }),
    }
    itemDefinitions.add(definition)
  }

  return itemDefinitions
}

const fileIcon =
  '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>'

import Plugin from '@ckeditor/ckeditor5-core/src/plugin'
import {
  toWidget,
  viewToModelPositionOutsideModelElement,
} from '@ckeditor/ckeditor5-widget/src/utils'
import Widget from '@ckeditor/ckeditor5-widget/src/widget'

import FieldCommand from './command'

export default class FieldEditing extends Plugin {
  static get requires() {
    return [Widget]
  }

  init() {
    this._defineSchema()
    this._defineConverters()

    this.editor.commands.add('type-field', new FieldCommand(this.editor))

    // Разобраться что это
    this.editor.editing.mapper.on(
      'viewToModelPosition',
      viewToModelPositionOutsideModelElement(this.editor.model, viewElement =>
        viewElement.hasClass('type-field')
      )
    )
  }

  _defineSchema() {
    const schema = this.editor.model.schema

    schema.register('type-field', {
      // Allow wherever text is allowed:
      allowWhere: '$text',
      // The textfield will act as an inline node:
      isInline: true,
      // The inline widget is self-contained so it cannot be split by the caret and it can be selected:
      isObject: true,
      // The inline widget can have the same attributes as text (for example linkHref, bold).
      allowAttributesOf: '$text',
      // The textfield can have many types, like date, name, surname, etc:
      allowAttributes: [
        'editor-container-id',
        'editor-container-type',
        'width',
      ],
    })
  }

  _defineConverters() {
    const conversion = this.editor.conversion

    conversion.for('upcast').elementToElement({
      view: {
        name: 'span',
        classes: ['type-field'],
      },
      model: (viewElement, { writer: viewWriter }) => {
        const id = viewElement.getAttribute('editor-container-id')
        const type = viewElement.getAttribute('editor-container-type')
        const width = viewElement.getAttribute('width')
        return viewWriter.createElement('type-field', {
          'editor-container-type': type,
          'editor-container-id': id,
          width: width,
        })
      },
    })

    conversion.for('dataDowncast').elementToElement({
      model: 'type-field',
      view: (modelItem, { writer: viewWriter }) => {
        const id = modelItem.getAttribute('editor-container-id')
        const type = modelItem.getAttribute('editor-container-type')
        const width = modelItem.getAttribute('width')
        return viewWriter.createContainerElement('span', {
          class: `type-field`,
          'editor-container-id': id,
          'editor-container-type': type,
          width: width,
        })
      },
    })

    conversion.for('editingDowncast').elementToElement({
      model: 'type-field',
      view: (modelItem, { writer: viewWriter }) => {
        const widgetElement = createTextFieldView(modelItem, viewWriter)
        return toWidget(widgetElement, viewWriter)
      },
    })

    const renderField = this.editor.config.get('fields').render

    // Helper method for both downcast converters.
    const createTextFieldView = (modelItem, viewWriter) => {
      const type = modelItem.getAttribute('editor-container-type')
      const id = modelItem.getAttribute('editor-container-id')
      const width = modelItem.getAttribute('width')

      const field = { type, id, width }
      const fieldView = viewWriter.createContainerElement('span', {
        class: `type-field d-inline-block`,
        'editor-container-id': field.id,
        'editor-container-type': field.type,
        width: field.width,
      })

      const vueWrappers = viewWriter.createRawElement(
        'span',
        {
          class: `editor__${field.type}`,
          'editor-id': field.id,
          'editor-type': field.type,
        },
        function (domElement) {
          renderField({ id, type, domElement })
        }
      )

      viewWriter.insert(viewWriter.createPositionAt(fieldView, 0), vueWrappers)

      return fieldView
    }
  }
}

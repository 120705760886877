<template>
  <span
    :contenteditable="false"
    :ref="field.id"
    :id="`customer-field-${field.id}`"
    :class="isValidField ? '' : 'customers-field-invalid'"
  >
    <span>{{ viewValue || $t('editor.selectValue') }}</span>
    <b-popover
      v-if="editorType === 'editor'"
      :target="`customer-field-${field.id}`"
      :title="$t('forms.labels.selectOption')"
      variant="info"
      triggers="click"
      placement="top"
      custom-class="field-popover"
      @shown="e => e.relatedTarget.focus()"
    >
      <FormSelectGroup
        :value.sync="model"
        :options="
          options.map(t => ({
            ...t,
            label: $t(`forms.labels.${t.label}`),
          }))
        "
        :label="$t('forms.labels.selectOption')"
        @change="changeHandler"
      />
    </b-popover>
  </span>
</template>

<script>
import moment from 'moment'
import {
  ref,
  computed,
  onMounted,
  getCurrentInstance,
} from '@vue/composition-api'
import { BFormInput, BPopover, BButton, BRow, BCol } from 'bootstrap-vue'
import { FormInputGroup, FormSelectGroup } from '@/views/components/forms'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  components: {
    BFormInput,
    BPopover,
    BButton,
    BRow,
    BCol,
    FormSelectGroup,
    FormInputGroup,
  },
  props: {
    value: {
      type: [String, Date],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    field: {
      type: Object,
      default: () => ({
        defaultValue: '',
        id: '',
        value: null,
      }),
    },
    editorType: {
      type: String,
      default: 'viewer',
    },
  },
  setup(props, { emit }) {
    const { t } = useI18nUtils()
    const modelValue = ref(props.value)
    const field = ref(props.field)
    const model = ref(field.value.key)
    const options = ref(field.value.options || [])

    const viewField = computed(
      () => options.value?.find(opt => opt.value === model.value) || {}
    )

    const innerType = computed(() => viewField.value.innerType)
    const format = computed(() => viewField.value.format)
    const innerOptions = computed(() => viewField.value.innerOptions)
    const isValidField = computed(
      () => field.value.options.length > 0 || !!viewValue.value
    )

    const viewValue = computed(() => {
      if (innerType.value === 'select') {
        let option = innerOptions.value?.find(
          opt => opt.value === modelValue.value
        )
        if (!option) {
          option = innerOptions.value?.find(
            opt => opt.value === viewField.value.defaultValue
          )
        }
        return option?.label ? t(`forms.options.${option.label}`) : ''
      }
      if (innerType.value === 'date') {
        return moment(
          modelValue.value || viewField.value.defaultValue || new Date()
        ).format(format.value)
      }
      return props.editorType === 'editor'
        ? viewField.value.defaultValue
        : modelValue.value || viewField.value.defaultValue
    })

    const updateValue = value => {
      modelValue.value = value
      changeHandler(value)
    }

    const changeHandler = value => {
      emit('update:field', {
        id: field.value.id,
        key: value,
        type: field.value.type,
      })
    }

    onMounted(() => {
      const root = getCurrentInstance()?.proxy?.$root
      const el = document.querySelector('#app')
      function hidePopover() {
        root?.$emit('bv::hide::popover')
      }
      if (root) {
        root.$on('bv::popover::show', () => {
          el.addEventListener('click', hidePopover)
        })
        root.$on('bv::popover::hide', () => {
          el.removeEventListener('click', hidePopover)
        })
      }
    })

    return {
      model,
      viewValue,
      options,
      isValidField,
      changeHandler,
      updateValue,
    }
  },
}
</script>

<style lang="scss">
.editor {
  &__customerField {
    display: inline;
    outline: 0;
    transition: all 0.3s;
    cursor: pointer !important;
    background: rgba(0, 207, 232, 0.3);

    .customers-field-invalid {
      border-bottom: 1px solid rgba(220, 53, 69, 0.5);
    }
  }
}

@media print {
  .editor {
    &__customerField {
      background: transparent;
      border: 0;
      padding: 0;
      outline: 0;
    }
  }
}
</style>

<template>
  <component
    :is="componentName"
    :value="model"
    :editor-key="field.id"
    :field="field"
    :editor-type="type"
    :ref="field.id"
    @update:value="updateHandler"
    @update:field="updateFieldHandler"
    @click:field="focusHandler"
    @blur:field="blurHandler"
  />
</template>

<script>
import Vue from 'vue'
import { ref } from '@vue/composition-api'
import { BButton } from 'bootstrap-vue'
import Input from '@/views/components/editor/Input'
import Select from '@/views/components/editor/Select'
import Date from '@/views/components/editor/Date'
import List from '@/views/components/editor/List'
import TypeField from '@/views/components/editor/TypeField'
import CustomerField from '@/views/components/editor/CustomerField'
import AdditionalCustomersField from '@/views/components/editor/AdditionalCustomersField'
import QrField from '@/views/components/editor/QrField'

export default {
  components: { BButton },
  emits: ['update:field', 'focus:field', 'blur:field', 'delete:field'],
  props: {
    field: {
      type: Object,
      default: () => ({
        defaultValue: '',
        id: '',
        value: null,
      }),
    },
    type: {
      type: String,
      default: 'viewer',
    },
  },

  setup(props, { emit }) {
    const model = ref(props.field.value || props.field.defaultValue)

    const componentMap = {
      text: Input,
      select: Select,
      date: Date,
      list: List,
      qr: QrField,
      typeField: TypeField,
      customerField: CustomerField,
      masterField: TypeField,
      additionalCustomersField: AdditionalCustomersField,
    }

    const componentName = `inserter-${props.field.type}`
    Vue.component(componentName, componentMap[props.field.type])

    const focusHandler = () => {
      emit('focus:field')
    }

    const blurHandler = () => {
      emit('blur:field')
    }

    const updateHandler = value => {
      if (props.type === 'viewer') {
        emit('update:field', { ...props.field, value })
      } else {
        emit('update:field', { ...props.field, defaultValue: value })
      }
    }

    const updateFieldHandler = field => {
      emit('update:field', field)
    }

    const deleteFieldHandler = field => {
      emit('delete:field', field)
    }

    return {
      model,
      componentName,
      focusHandler,
      blurHandler,
      updateHandler,
      updateFieldHandler,
      deleteFieldHandler,
    }
  },
}
</script>

<style lang="scss">
.editor_field {
  &__delete {
    position: absolute !important;
    top: -6px;
    right: -6px;
    display: block;
  }
}
</style>

<template>
  <span
    :contenteditable="false"
    :id="`date-field-${field.id}`"
    :class="isValidField ? '' : 'date-field-invalid'"
  >
    <span>{{ viewValue }}</span>

    <b-popover
      v-if="!field.disabled"
      :target="`date-field-${field.id}`"
      :title="$t('forms.labels.date')"
      triggers="click"
      placement="top"
      custom-class="field-popover"
      @shown="e => e.relatedTarget.focus()"
    >
      <FormSelectGroup
        v-if="editorType === 'editor'"
        :value.sync="format"
        :options="dateFormats"
        :label="$t('forms.labels.dateFormat')"
        @change="formatHandler"
      />

      <b-calendar
        v-model="model"
        :date-format-options="{
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        }"
        locale="ru"
        class="mb-1 mb-md-0 field-calendar"
        @selected="inputHandler"
        label-help=""
      />
    </b-popover>
  </span>
</template>

<script>
import moment from 'moment'
import {
  ref,
  computed,
  onMounted,
  getCurrentInstance,
} from '@vue/composition-api'
import {
  BFormInput,
  BPopover,
  BButton,
  BRow,
  BCol,
  BCalendar,
} from 'bootstrap-vue'
import { FormInputGroup, FormSelectGroup } from '@/views/components/forms'
import { dateFormats } from '@/utils/config'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  components: {
    BFormInput,
    BPopover,
    BButton,
    BRow,
    BCol,
    BCalendar,
    FormSelectGroup,
    FormInputGroup,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    field: {
      type: Object,
      default: () => ({
        defaultValue: '',
        id: '',
        value: null,
        disabled: false,
      }),
    },
    editorType: {
      type: String,
      default: 'viewer',
    },
  },
  setup(props, { emit }) {
    const { locale } = useI18nUtils()
    const defaultFormat = 'DD.MM.YYYY'
    const format = ref(props.field.format || defaultFormat)
    const currentLocale = computed(() => locale())
    const now = new Date()
    const model = ref(props.value || now)
    const viewValue = computed(() =>
      moment(model.value).locale(currentLocale.value).format(format.value)
    )

    const inputHandler = date => {
      if (props.editorType === 'viewer') {
        emit('update:field', {
          ...props.field,
          value: date,
        })
      } else {
        emit('update:field', {
          ...props.field,
          defaultValue: date,
        })
      }
    }

    const formatHandler = format => {
      emit('update:field', {
        ...props.field,
        format,
        defaultValue: model.value,
      })
    }
    const isValidField = computed(() => !!viewValue.value)

    onMounted(() => {
      const root = getCurrentInstance()?.proxy?.$root
      const el = document.querySelector('#app')
      function hidePopover() {
        root?.$emit('bv::hide::popover')
      }
      if (root) {
        root.$on('bv::popover::show', () => {
          el.addEventListener('click', hidePopover)
        })
        root.$on('bv::popover::hide', () => {
          el.removeEventListener('click', hidePopover)
        })
      }
    })

    return {
      model,
      format,
      dateFormats,
      viewValue,
      isValidField,
      inputHandler,
      formatHandler,
    }
  },
}
</script>

<style lang="scss">
.editor {
  &__date {
    display: inline-block;
    outline: 0;
    transition: all 0.3s;
    cursor: pointer !important;
    background: rgba(115, 103, 240, 0.3);
    .date-field-invalid {
      border-bottom: 1px solid rgba(220, 53, 69, 0.5);
    }
  }
}

.b-calendar {
  max-width: 100%;

  .b-calendar-inner {
    min-width: auto;
  }
}
@media print {
  .editor {
    &__date {
      background: transparent;
      border: 0;
      padding: 0;
      outline: 0;
    }
  }
}
</style>
